import { useCreateBabble } from "../../util/queries";
import styled from "styled-components";
import { BabbleDto } from "../../types";
import { openChat } from "./util";

const SimpleButton = styled.button`
  all: unset;
  cursor: pointer;
  padding: 10px;
  text-decoration: underline;
`;

export const AddBabbleButton = () => {
  const [createBabble] = useCreateBabble();

  const onAddBabble = () => {
    const text = prompt("Enter babble text");
    if (text) {
      createBabble(text);
    }
  };

  return <SimpleButton onClick={onAddBabble}>Add new topic</SimpleButton>;
};

export const ExportTopics = ({ topics }: { topics: BabbleDto[] }) => {
  const onExportTopics = () => {
    const prompt = `These are some conversation topics I liked:
${topics.map((topic) => topic.text).join("\n")}

Offer more topics.
Put each into a box which content I can copy (like a piece of programming code).    
    `;

    openChat(prompt);
  };

  return <SimpleButton onClick={onExportTopics}>Export topics</SimpleButton>;
};
